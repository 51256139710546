import React, { useContext } from 'react';
import '../App.css';
import { DashboardContext } from '../context/DashboardContext';

const BalanceInfo = () => {

    const { currentBalance } = useContext(DashboardContext);

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-row items-center justify-between">
            <div className="text-2xl font-bold mb-2">USDT : {currentBalance.USDT}</div>
            <div className="text-2xl font-bold mb-2">BTC : {currentBalance.BTC} ({+currentBalance.BTC * +currentBalance.BTCUSDT})</div>
            <div className="text-2xl font-bold mb-2">Total : {+currentBalance.USDT + +currentBalance.BTC * +currentBalance.BTCUSDT}</div>
        </div>
    );
};

export default BalanceInfo;