import React, { useContext } from 'react';
import { DashboardContext } from '../context/DashboardContext';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../App.css';

const TimePeriodSelector = () => {

    const { startDate, setStartDate, endDate, setEndDate } = useContext(DashboardContext);

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex items-center justify-center space-x-5">
            <div className="text-2xl font-bold mb-2">Show period from</div>
            <div className="single-date-selector">
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                />
            </div>
            <div className="text-2xl font-bold mb-2">to</div>
            <div className="single-date-selector">
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                />
            </div>
        </div>
    );

};
export default TimePeriodSelector;