import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { DashboardContext } from '../context/DashboardContext';

const StatusPanel = () => {

    const { currentState } = useContext(DashboardContext);
    const [reportText, setReportText] = useState('');


    useEffect(() => {
        let text = '';
        switch (currentState.status) {
            case 'WAITING':
                text = 'Waiting until price gets to buy or sell zone.';
                break;
            case 'SELLING':
                text = 'Will sell when price drops to ' + currentState.currentStopPrice;
                break;
            case 'BUYING':
                text = 'Will buy when price rises to ' + currentState.currentStopPrice;
                break;
            case 'DISABLED':
                text = 'Currently disabled. Will not buy or sell.';
                break;
            default:
                text = 'Unknown status: ' + currentState.status;
        }
        setReportText(text);
    }, [currentState]);

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col items-center justify-center text-center font-bold text-lg">
            {reportText}
        </div>
    );
};

export default StatusPanel;