import React, { createContext, useState, useEffect } from 'react';

export const DashboardContext = createContext();

export const DashboardContextProvider = ({ children }) => {

  const [currentBalance, setCurrentBalance] = useState({
    'BTC': 0,
    'BTCUSDT': 0,
    'USDT': 0,
    'timestamp': new Date().toISOString()
  });

  const [currentState, setCurrentState] = useState({});
  const [priceChartData, setPriceChartData] = useState([]);
  const [ordersChartData, setOrdersChartData] = useState([]);
  const [balanceChartData, setBalanceChartData] = useState([]);

  const [startDate, setStartDate] = useState( new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState( new Date());

  useEffect(() => {
    const url = '/api/state';

    fetch(url, {
      headers: {
        'x-Auth-Token': localStorage.getItem('apiKey')
      }
    })
      .then(response => response.json())
      .then(data => setCurrentState(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []); // The empty array ensures this effect runs only once after the initial render


  return (
    <DashboardContext.Provider value={ { currentState, currentBalance, setCurrentBalance, priceChartData, setPriceChartData, ordersChartData, setOrdersChartData, balanceChartData,  startDate, setBalanceChartData, setStartDate, endDate, setEndDate } }>
      {children}
    </DashboardContext.Provider>
  );
};