import React, { useEffect, useContext, useState } from 'react';
import { DashboardContext } from '../context/DashboardContext';

const BalanceFetcher = () => {

    const { setBalanceChartData, currentBalance, setCurrentBalance, startDate, endDate } = useContext(DashboardContext);

    const [historyBalance, setHistoryBalance] = useState([]);

    const fetchCurrentBalance = async () => {
        const url = '/api/balance';
        
        fetch (url, { 
              headers: {
                'x-Auth-Token': localStorage.getItem('apiKey')
              }
        })
        .then(response => response.json())
        .then(currentBalance => setCurrentBalance(currentBalance))
    };

    const fetchHistoryBalance = async () => {
        let url = `/api/balance/range?startdate=${startDate.toISOString().slice(0, 18)}&enddate=${endDate.toISOString().slice(0, 18)}`;
        // Fetch the price data from the API
        const response = await fetch(url, { 
            headers: {
                'x-Auth-Token': localStorage.getItem('apiKey')
            }
        });
        const data = await response.json();
        setHistoryBalance(data);
    };

    useEffect(() => {
        fetchCurrentBalance();
    }, []);

    useEffect(() => {
        fetchHistoryBalance();
    }, [startDate, endDate]);

    useEffect(() => {
        if (Array.isArray(historyBalance)) {
            setBalanceChartData([...historyBalance, currentBalance]);
        }
    }, [historyBalance]);

    useEffect(() => {
        if (Array.isArray(historyBalance)) {
            setBalanceChartData([...historyBalance, currentBalance]);
        }
    }, [currentBalance]);
};

export default BalanceFetcher;