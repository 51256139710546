import React from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';
import Login from './pages/Login'; // Assuming you have a Login component
import Admin from './pages/Admin'; // Assuming you have an Admin component
import './App.css';
import "react-datepicker/dist/react-datepicker.css";

function App() {


  return (
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<Admin/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/admin" element={<Admin/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;