import React, { useEffect, useRef, useContext } from 'react';
import Chart from 'chart.js/auto'; // Використовуємо Chart.js для побудови графіка
import '../App.css';
import { DashboardContext } from '../context/DashboardContext';
import ConfigContext from '../context/ConfigContext';
import 'chartjs-adapter-date-fns';

const PriceChart = () => {

    const { priceChartData, currentState, ordersChartData } = useContext(DashboardContext);
    const { config } = useContext(ConfigContext);

    const chartRef = useRef(null); // Ref for the canvas element
    const chartInstanceRef = useRef(null); // Ref to store the chart instance

    useEffect(() => {
        // Function to create a chart
        const createChart = () => {
            const ctx = chartRef.current.getContext('2d');
            // Destroy the existing chart instance if it exists
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            let datasets = [
                {
                    label: 'Price',
                    data: priceChartData.map(data => ({
                        x: new Date(data.timestamp), // Use the timestamp as the x value
                        y: data.price
                    })),
                    borderColor: 'rgba(75, 192, 192, 1)',
                    fill: false,
                    tension: 0.1,
                    order: 4,
                },
                {
                    label: 'Buy Zone',
                    data: priceChartData.map(data => ({
                        x: new Date(data.timestamp), // Use the timestamp as the x value // Use the timestamp as the x value
                        y: config.buyZoneLimit
                    })),
                    fill: 'start', // Fill from the start (bottom) up to the line
                    backgroundColor: 'rgba(0, 255, 0, 0.2)', // Transparent green fill
                    borderColor: 'green', // No border for this dataset
                    pointRadius: 0, // No points
                    tension: 0.1,
                    order: 3,
                }
                ,
                {
                    label: 'Sell Zone',
                    data: priceChartData.map(data => ({
                        x: new Date(data.timestamp), // Use the timestamp as the x value // Use the timestamp as the x value
                        y: config.sellZoneLimit
                    })),
                    fill: 'end', // Fill from the end (top) up to the line
                    backgroundColor: 'rgba(255, 0, 0, 0.2)', // Transparent green fill
                    borderColor: 'red', // No border for this dataset
                    pointRadius: 0, // No points
                    tension: 0.1,
                    order: 2,
                },
                {
                    label: 'Orders',
                    data: ordersChartData.map(order => ({
                        x: new Date(order.timestamp), // Use the timestamp as the x value // Use the order timestamp as the x value
                        y: order.price,
                        // Additional properties for styling based on order type
                    })),
                    backgroundColor: ordersChartData.map(data => data.type === 'SELL' ? 'red' : 'green'), // Color based on order type
                    borderColor: ordersChartData.map(data => data.type === 'SELL' ? 'red' : 'green'),
                    pointStyle: ordersChartData.map(data => data.type === 'SELL' ? 'triangle' : 'rectRot'), // Use different shapes or you can use custom images
                    pointRadius: 5, // Adjust as needed
                    pointHoverRadius: 7, // Adjust as needed
                    showLine: false, // Only show points, no line
                    order: 1,
                }
            ]

            console.log(currentState);
            if (currentState && currentState.currentStopPrice > 0.0) {
                datasets = [...datasets, {
                    label: 'Stop Price',
                    data: priceChartData.map(data => ({
                        x: new Date(data.timestamp), 
                        y: currentState.currentStopPrice
                    })),
                    fill: false,
                    borderColor: 'purple',
                    pointRadius: 0,
                    tension: 0.1,
                }];
            }

            // Create a new chart instance
            chartInstanceRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: datasets
                },
                options: {
                    responsive: true, // Ensure the chart resizes with the container
                    scales: {
                        x: {
                            type: 'time', // Define x-axis as a time scale
                            time: {
                                unit: 'day', // Adjust based on your data's granularity
                                // Further time scale configuration as needed
                            },
                            // Additional scale configuration as needed
                        },
                        y: {
                            beginAtZero: false,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // This will hide the legend
                        }
                    },
                }
            });
        }
        createChart();
    }, [priceChartData, ordersChartData, config, currentState]);



    return (
        <div className="chart-container bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-row items-center justify-between">
            <canvas ref={chartRef}></canvas>
        </div>
    );
};

export default PriceChart;