import React, { useContext } from 'react';
import '../App.css';
import { ConfigContext } from '../context/ConfigContext';

const ConfigEditor = () => {
    const { config, setConfig } = useContext(ConfigContext);

    const onSave = async () => {
        try {
            const url = '/api/config';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-Auth-Token': localStorage.getItem('apiKey'),
                },
                body: JSON.stringify(config)
            });
            if (!response.ok) {
                throw new Error('Failed to save config');
            }
            // Handle success response
            alert('Config saved successfully');
        } catch (error) {
            console.error('Error saving config:', error);
            // Handle error response
            alert('Error saving config');
        }
    };

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-row items-center justify-between">
            <div className="flex items-center space-x-20" >
                <div className="space-y-2">
                    <label htmlFor="buyAt" className="text-sm font-medium text-gray-700">
                        Buy At:
                    </label>
                    <input
                        type="number"
                        id="buyAt"
                        value={config.buyZoneLimit || ''}
                        onChange={(e) => setConfig(config => ({ ...config, buyZoneLimit: parseFloat(e.target.value) }))}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-center"
                        style={{ width: 'auto' }}
                    />
                </div>

                <div className="space-y-2">
                    <label htmlFor="sellAt" className="block text-sm font-medium text-gray-700">
                        Sell At:
                    </label>
                    <input
                        type="number"
                        id="sellAt"
                        value={config.sellZoneLimit || ''}
                        onChange={(e) => setConfig(config => ({ ...config, sellZoneLimit: parseFloat(e.target.value) }))}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-center"
                        style={{ width: 'auto' }}
                    />
                </div>

                <div className="space-y-2">
                    <label htmlFor="tradeAmount" className="block text-sm font-medium text-gray-700">
                        Trade Amount:
                    </label>
                    <input
                        type="number"
                        id="tradeAmount"
                        value={config.tradeAmount || ''}
                        onChange={(e) => setConfig(config => ({ ...config, tradeAmount: parseFloat(e.target.value) }))}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-center"
                        style={{ width: 'auto' }}
                    />
                </div>

                <div className="space-y-2">
                    <label htmlFor="limitOrderDistance" className="block text-sm font-medium text-gray-700">
                        Stop price distance:
                    </label>
                    <input
                        type="number"
                        id="limitOrderDistance"
                        value={config.limitOrderDistance || ''}
                        onChange={(e) => setConfig(config => ({ ...config, limitOrderDistance: parseFloat(e.target.value) }))}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-center"
                        style={{ width: 'auto' }}
                    />
                </div>

                <div className="space-y-2">
                    <label htmlFor="bounceDistance" className="block text-sm font-medium text-gray-700">
                        Bounce distance:
                    </label>
                    <input
                        type="number"
                        id="bounceDistance"
                        value={config.bounceDistance || ''}
                        onChange={(e) => setConfig(config => ({ ...config, bounceDistance: parseFloat(e.target.value) }))}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-center"
                        style={{ width: 'auto' }}
                    />
                </div>

                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="isEnabled"
                        checked={config.enabled}
                        onChange={(e) => setConfig(config => ({ ...config, enabled: e.target.checked }))}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label htmlFor="isEnabled" className="ml-2 block text-sm text-gray-700">
                        Enabled
                    </label>
                </div>

                <button
                    onClick={onSave}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default ConfigEditor;