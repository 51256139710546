import React, { useEffect, useContext } from 'react';
import { DashboardContext } from '../context/DashboardContext';

const OrderFetcher = () => {

    const { setOrdersChartData, startDate, endDate } = useContext(DashboardContext);

    const fetchOrderData = async () => {
        let url = `/api/orders?startdate=${startDate.toISOString().slice(0, 18)}&enddate=${endDate.toISOString().slice(0, 18)}`;
        // Fetch the price data from the API
        const response = await fetch(url, {
            headers: {
                'x-Auth-Token': localStorage.getItem('apiKey')
            }
        });
        const data = await response.json();
        setOrdersChartData(data);
    };

    useEffect(() => {
        fetchOrderData();
    }, [startDate, endDate]);

};

export default OrderFetcher;