import React, { useEffect, useRef, useContext } from 'react';
import Chart from 'chart.js/auto'; // Використовуємо Chart.js для побудови графіка
import '../App.css';
import { DashboardContext } from '../context/DashboardContext';

const BalanceChart = () => {

    const { balanceChartData } = useContext(DashboardContext);

    const chartRef = useRef(null); // Ref for the canvas element
    const chartInstanceRef = useRef(null); // Ref to store the chart instance

    useEffect(() => {
        // Function to create a chart
        const createChart = () => {
            const ctx = chartRef.current.getContext('2d');
            // Destroy the existing chart instance if it exists
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
            // Create a new chart instance
            chartInstanceRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: balanceChartData.map(data => new Date(data.timestamp).toLocaleString()),
                    datasets: [
                        {
                            label: 'Total Balance',
                            data: balanceChartData.map(data => +data.BTC * +data.BTCUSDT + +data.USDT),
                            borderColor: 'rgba(255, 255, 255, 0.9)', // black color
                            fill: false,
                            tension: 0.1,
                        },
                        {
                            label: 'BTC Balance',
                            data: balanceChartData.map(data => +data.BTC * +data.BTCUSDT),
                            borderColor: 'rgba(255, 255, 0, 0.9)', // yellow color
                            backgroundColor: 'rgba(255, 255, 0, 0.4)', 
                            fill: 'start', // Fill to the previous dataset in the stack
                            tension: 0.1,
                            stack: 'combined', // Group this dataset with the USDT Balance dataset
                        },
                        {
                            label: 'USDT Balance',
                            data: balanceChartData.map(data => +data.USDT),
                            fill: "-1",
                            backgroundColor: 'rgba(0, 255, 0, 0.4)', // green color
                            borderColor: 'rgba(0, 255, 0, 0.9)',
                            tension: 0.1,
                            stack: 'combined', // Group this dataset with the Total Balance dataset
                        }
                    ],
                },
                options: {
                    scales: {
                        x: {
                            ticks: {
                                maxTicksLimit: 5, // You want to limit the number of labels
                                autoSkip: true, // Enable automatic skipping of ticks
                                maxRotation: 0, // Keep labels horizontal
                                source: 'auto', // Let Chart.js decide the best way to generate ticks
                            },
                        },
                        y: {
                            beginAtZero: true,
                            stacked: true,
                        },
                    },
                }
            });
        }
        createChart();
    }, [balanceChartData]);



    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-row items-center justify-between">
            <canvas ref={chartRef}></canvas>
        </div>
    );
};

export default BalanceChart;