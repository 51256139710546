import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import PriceChart from '../components/PriceChart';
import PriceFetcher from '../components/PriceFetcher';
import TimePeriodSelector from '../components/TimePeriodSelector';
import ConfigEditor from '../components/ConfigEditor';
import { DashboardContextProvider } from '../context/DashboardContext';
import { ConfigContextProvider } from '../context/ConfigContext';
import BalanceInfo from '../components/BalanceInfo';
import BalanceFetcher from '../components/BalanceFetcher';
import BalanceChart from '../components/BalanceChart';
import StatusPanel from '../components/StatusPanel';
import OrderFetcher from '../components/OrderFetcher';

function Admin() {

    const navigate = useNavigate();

    useEffect(() => {
        const apiKey = localStorage.getItem('apiKey');
        if (!apiKey) {
          navigate('/login');
        }
      }, [navigate]);


    return (
        <div className="Admin">
            <ConfigContextProvider>
                <DashboardContextProvider>
                    <StatusPanel />
                    <ConfigEditor />
                    <PriceFetcher />
                    <OrderFetcher />
                    <PriceChart />
                    <BalanceInfo />
                    <TimePeriodSelector />
                    <BalanceFetcher />
                    <BalanceChart />
                </DashboardContextProvider>
            </ConfigContextProvider>
        </div >
    );
}

export default Admin;