import React, { createContext, useState, useEffect } from 'react';

export const ConfigContext = createContext();

export const ConfigContextProvider = ({ children }) => {

  const [config, setConfig] = useState({
    enabled: false,
    buyZoneLimit: 0.0,
    sellZoneLimit: 0.0
  });

  useEffect(() => {
    const url = '/api/config';

    fetch(url, {
      headers: {
        'x-Auth-Token': localStorage.getItem('apiKey')
      }
    })
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;