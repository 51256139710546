import React, { useEffect, useContext } from 'react';
import { DashboardContext } from '../context/DashboardContext';

const PriceFetcher = () => {

    const { setPriceChartData, startDate, endDate } = useContext(DashboardContext);

    const fetchPriceData = async () => {
        let url = `/api/prices/range?startdate=${startDate.toISOString().slice(0, 18)}&enddate=${endDate.toISOString().slice(0, 18)}`;
        // Fetch the price data from the API
        const response = await fetch(url, { 
            headers: {
                'x-Auth-Token': localStorage.getItem('apiKey')
            }
        });
        const data = await response.json();
        setPriceChartData(data);
    };

    useEffect(() => {
        fetchPriceData();
    }, [startDate, endDate]);

};

export default PriceFetcher;